import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonLoading } from '@ionic/react';
import React, { useState } from 'react';
import { Topups } from '../data/topup';
import configData from '../data/env.json';
import Axios from 'axios';
import './TopupListItem.css';
import { alertMessage } from '../data/alert';

interface TopupListItemProps {
    topups: Topups;
}

const TopupListItem: React.FC<TopupListItemProps> = ({ topups }) => {

    const [showLoading, setShowLoading] = useState(false);

    const config = configData.env[configData.isProd];
    const api = Axios.create({
        baseURL: config.apiUrl
    });
    const returnUrl = window.location.origin + '/home';
    const options = {
        headers: { 'Apikey': 'KelantanPay', 'ReturnUrl': returnUrl }
    };
    var xs_token: any = window.localStorage.getItem('xs_token');

    function getPaymentGateway(id: number) {
        setShowLoading(true);
        api.post('v3/api/topup/to-billplz', { xs_token: xs_token, topup_id: id }, options)
            .then((res) => {
                if (res.status === 200) {
                    var meta = res.data.split('url=')[1];
                    meta = meta.split('"')[0];
                    window.location.href = meta;
                    setShowLoading(false);
                }
            }).catch((e) => {
                setShowLoading(false);
                alertMessage(e.response.data.message);
            })
    }
    return (
        <IonCol size="6">
            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'} />
            <IonCard className="cardMargin">
                <IonCardHeader>{topups.token_amount} Tokens</IonCardHeader>

                <IonCardContent className="cardContent">
                    <IonButton fill="solid" slot="end" onClick={e => getPaymentGateway(topups.id)}>RM {topups.paid_amount / 100}.00</IonButton>
                </IonCardContent>
            </IonCard>
        </IonCol>
    );
};

export default TopupListItem;