import { IonAlert, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonSelect, IonSelectOption, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import configData from '../data/env.json';
import Axios from 'axios';
import { alertMessage } from '../data/alert';
import './AddParking.css';
import { chevronBackOutline } from 'ionicons/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { fetchVehicles, Vehicles } from '../data/vehicle';

interface AddParkingProps extends RouteComponentProps<{ id: string }> { }

const AddParking: React.FC<AddParkingProps> = ({ match, history }) => {
    library.add(faPencilAlt);

    const config = configData.env[configData.isProd]
    const api = Axios.create({
        baseURL: config.apiUrl
    })

    const [duration, setDuration] = useState([]);
    const [plates, setPlateNumber] = useState<Vehicles[]>([]);
    const [selectedPlateNo, setSelectedPlateNo] = useState('');
    const [selectedDuration, setSelectedDuration] = useState('');
    const [lotNumber, setLotNumber] = useState<number>(0);
    const [showLoading, setShowLoading] = useState(false);
    const [showAlert1, setShowAlert1] = useState(false);
    var xs_token: any = window.localStorage.getItem('xs_token');
    var pbt_name: any = window.localStorage.getItem('pbt_name');
    const zoneString: any = window.localStorage.getItem('selectedZone');
    const zone = JSON.parse(zoneString);
    var pbt_logo: any = window.localStorage.getItem('pbt_logo');
    const appVersion = '4.0.0';
    const platform = 'android';
    const options = {
        headers: { 'Apikey': 'KELANTANPAY' }
    };

    function doAddParking() {
        setShowLoading(true);
        api.post('/v3/api/parking/add', { xs_token: xs_token, duration: selectedDuration, zone_id: parseInt(match.params.id), plate_number: selectedPlateNo, parking_lot: lotNumber, app_version: appVersion, platform: platform }, options)
            .then((res) => {
                setShowLoading(false);
                if (res.status === 200) {
                    window.localStorage.setItem('confirmationMessage', res.data.response.message);
                    window.localStorage.setItem('hashString', res.data.response.hash);
                    setShowAlert1(true);
                }
            }).catch((err) => {
                alertMessage(err.response.data.message);
                setShowLoading(false);
            });
    }
    var confirmationMessage: any = window.localStorage.getItem('confirmationMessage');
    var hashString: any = window.localStorage.getItem('hashString');

    function doAddParkingHash(hashString: string) {
        api.post('/v3/api/parking/add', { xs_token: xs_token, duration: selectedDuration, zone_id: parseInt(match.params.id), plate_number: selectedPlateNo, parking_lot: lotNumber, hash: hashString, app_version: appVersion, platform: platform }, options)
            .then((response) => {
                if (response.status === 200) {
                    history.replace('/home', { data: response.data });
                }
            }).catch((err) => {
                alertMessage(err.response.data.message);
                setShowLoading(false);
            });
    }

    function getPbtLists() {
        history.replace('/parking');
    }

    function getPlateNumbers() {
        setShowLoading(true);
        fetchVehicles(xs_token).then((res: any) => {
            setPlateNumber(res);
            setShowLoading(false);
        });
    }

    function getSelectedPlate(plateNum: string) {
        setSelectedPlateNo(plateNum);
    }

    function getSelectedDuration(dur: string) {
        setSelectedDuration(dur);
    }

    function getDurationLists() {
        setShowLoading(true);
        api.post('api/v4/parking/duration', { xs_token: xs_token, plate_number: selectedPlateNo, zone_id: parseInt(match.params.id), parking_lot: lotNumber }, options)
            .then((res) => {
                setShowLoading(false);
                if (res.status === 200) {
                    setDuration(res.data.durations);
                } else {
                    alertMessage(res.data.message);
                }
            }).catch((err) => {
                alertMessage(err.response.data.message);
                setShowLoading(false);
            })
    }

    function checkRequireLotNumber() {
        if (zone.is_require_parking_lot == 1) {
            return (
                <IonCard>
                    <IonCardHeader>
                        <IonLabel position="stacked">Lot Number</IonLabel>
                    </IonCardHeader>
                    <IonCardContent>
                        <p><small>Please enter valid parking lot number {zone.lot_ranges}</small></p>
                        <IonItem>
                            <IonInput type="number" onIonChange={(e: any) => { setLotNumber(e.target.value) }}></IonInput>
                        </IonItem>
                    </IonCardContent>
                </IonCard>
            )
        }
    }

    const plateNumberActionSheetOptions = {
        header: 'Plate Number',
        subHeader: 'Select plate number'
    };

    const durationActionSheetOptions = {
        header: 'Durations',
        subHeader: 'Select duration'
    };

    return (
        <IonPage id="add-parking-page">
            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton routerLink="/parking">
                                    <IonIcon slot="start" icon={chevronBackOutline} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>Add Parking</IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <IonAlert
                    isOpen={showAlert1}
                    onDidDismiss={() => setShowAlert1(false)}
                    cssClass='my-custom-class'
                    header={'Confirm Parking'}
                    message={confirmationMessage}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                console.log('Confirm Cancel: blah');
                            }
                        },
                        {
                            text: 'Confirm',
                            handler: () => {
                                doAddParkingHash(hashString);
                            }
                        }
                    ]}
                />

                <IonCard onClick={e => getPbtLists()}>
                    <IonCardHeader>
                        <FontAwesomeIcon icon="pencil-alt" className="editPbtIcon"></FontAwesomeIcon>
                        <IonItem lines="none">
                            <img className="pbtLogo" src={pbt_logo} alt="pbt_logo"></img>
                            <IonLabel className="ion-text-wrap pbtName">{pbt_name}</IonLabel>
                        </IonItem>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonLabel className="ion-text-center zoneName">
                            <div className="ion-text-center">
                                {zone.zone_code}
                            </div>
                        </IonLabel>
                    </IonCardContent>
                </IonCard>

                <IonCard onClick={e => getPlateNumbers()}>
                    <IonCardHeader>
                        <IonLabel>Plate Number</IonLabel>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonSelect
                            interfaceOptions={plateNumberActionSheetOptions}
                            interface="action-sheet"
                            placeholder="Select plate number"
                            onIonChange={(e) => getSelectedPlate(e.detail.value)}
                            value={selectedPlateNo}
                        >
                            {plates.map(m =>
                                <IonSelectOption key={m.id} value={m.plate_number}>
                                    {m.plate_number}
                                </IonSelectOption>
                            )}
                        </IonSelect>
                    </IonCardContent>
                </IonCard>

                {checkRequireLotNumber()}

                <IonCard onClick={e => getDurationLists()}>
                    <IonCardHeader>
                        <IonLabel>Duration</IonLabel>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonSelect
                            interfaceOptions={durationActionSheetOptions}
                            interface="action-sheet"
                            placeholder="Select duration"
                            onIonChange={(e) => getSelectedDuration(e.detail.value)}
                            value={selectedDuration}
                        >
                            {duration.map((m: any) =>
                                <IonSelectOption key={m.duration} value={m.duration}>
                                    {m.name}
                                </IonSelectOption>
                            )}
                        </IonSelect>
                    </IonCardContent>
                </IonCard>

                <IonButton expand="block" shape="round" onClick={(e) => { doAddParking(); }}>Pay Now</IonButton>

            </IonContent>
        </IonPage>
    );
};

export default AddParking;