import configData from '../data/env.json';
import Axios from 'axios';

export interface Zones {
    id: number;
    zone_code: string;
    pbt_name: string;
    pbt_info: string;
    pbt_logo: string;
    lot_ranges: string;
    zone_type: string;
    total_bays: number;
    toc: string;
    parking_rate: string;
    lat: number;
    lng: number;
    radius: number;
    is_enable_adv: boolean;
    is_monthly_pass: boolean;
    operation_status: boolean;
    is_require_parking_lot: boolean;
    is_daily_pass: boolean
}

var zones: Zones[] = [];

const config = configData.env[configData.isProd];
const api = Axios.create({
    baseURL: config.apiUrl
});

export const fetchZones = (xs_token: string, pbtId: number) => {
    return new Promise((resolve, reject) => {
        api.post('/v3/api/parking/zone', { xs_token: xs_token, pbt_id: pbtId })
            .then((res) => {
                if (res.status === 200) {
                    zones = res.data.response.zones;
                    resolve(zones);
                } else {
                    reject(res);
                }
            }).catch((e) => {
                console.log(e);
                reject(e.getMessage());
            });
    });
};