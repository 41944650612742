import React, { useState } from 'react';
import {
    IonActionSheet,
    IonAlert,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonLabel,
    IonLoading
} from '@ionic/react';
import { ActiveParking } from '../data/active';
import moment from 'moment';
import './ActiveParkingListItem.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCar, faClock, faRoad } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import configData from '../data/env.json';
import Axios from 'axios';
import { alertMessage } from '../data/alert';

interface ActiveParkingListItemProps {
    activeParking: ActiveParking;
}

const config = configData.env[configData.isProd]
const api = Axios.create({
    baseURL: config.apiUrl
})

const ActiveParkingListItem: React.FC<ActiveParkingListItemProps> = ({ activeParking }) => {

    library.add(faRoad, faCar, faClock);
    var [duration, setDuration] = useState('');
    var startTime = moment.unix(activeParking.start_time);
    var endTime = moment.unix(activeParking.end_time);
    const [durationList, setDurationList] = useState([]);
    const [showDurationSheet, setshowDurationSheet] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [showAlert1, setShowAlert1] = useState(false);
    var xs_token: any = window.localStorage.getItem('xs_token');
    const appVersion = '4.0.0';
    const platform = 'android';
    const options = {
        headers: { 'Apikey': 'KELANTANPAY' }
    };

    window.setTimeout(() => {
        var curTime = moment().unix();
        var duration = activeParking.end_time - curTime;

        var hours = duration / 3600;
        var minutes = (duration % 3600) / 60;
        var seconds = duration % 60;

        var output = '';

        hours = Math.floor(hours);
        minutes = Math.floor(minutes);
        if (hours > 0) {
            output += hours.toString() + ' hours ';
        }
        if (minutes > 0) {
            output += minutes.toString() + ' minutes ';
        }
        if (seconds > 0) {
            output += seconds.toString() + ' seconds';
        } else {
            output += '0 seconds';
        }

        setDuration(output);
    }, 1000);

    function getDurationLists() {
        setShowLoading(true);
        api.post('api/v4/parking/duration', { xs_token: xs_token, plate_number: activeParking.plat_no, zone_id: activeParking.zone_id, parking_lot: activeParking.parking_lot, app_version: appVersion, platform: platform }, options)
            .then((res) => {
                setShowLoading(false);
                if (res.status === 200) {
                    setDurationList(res.data.durations);
                    setshowDurationSheet(true);
                } else {
                    alertMessage(res.data.message);
                }
            }).catch((err) => {
                alertMessage(err.response.data.message);
                setShowLoading(false);
            })
    }

    function selectedDuration(durationSelected: number) {
        return new Promise((resolve, reject) => {
            resolve({ durationIs: durationSelected });
        });
    }

    function doExtendParking(dura: number) {
        setShowLoading(true);
        api.post('/v3/api/parking/add', { xs_token: xs_token, duration: dura, zone_id: activeParking.zone_id, plate_number: activeParking.plat_no, parking_lot: activeParking.parking_lot, app_version: appVersion, platform: platform })
            .then((res) => {
                setShowLoading(false);
                if (res.status === 200) {
                    window.localStorage.setItem('confirmationMessage', res.data.response.message);
                    window.localStorage.setItem('hashString', res.data.response.hash);
                    setShowAlert1(true);
                }
            }).catch((err) => {
                alertMessage(err.response.data.message);
                setShowLoading(false);
            });
    }

    var confirmationMessage: any = window.localStorage.getItem('confirmationMessage');
    var hashString: any = window.localStorage.getItem('hashString');
    var selectDuration: any = window.localStorage.getItem('dura');

    function doAddParkingHash(dura: number, hashString: string) {
        api.post('/v3/api/parking/add', { xs_token: xs_token, duration: dura, zone_id: activeParking.zone_id, plate_number: activeParking.plat_no, parking_lot: activeParking.parking_lot, hash: hashString, app_version: appVersion, platform: platform })
            .then((response) => {
                if (response.status === 200) {
                    window.location.reload();
                }
            }).catch((err) => {
                alertMessage(err.response.data.message);
            })
    }

    return (
        <IonCard>
            <IonCardHeader>
                <h5 className="adjustMargin">{duration}</h5>
                <p className="adjustMargin"><small>parking to expire</small></p>
            </IonCardHeader>

            <IonCardContent>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <IonAlert
                    isOpen={showAlert1}
                    onDidDismiss={() => setShowAlert1(false)}
                    cssClass='my-custom-class'
                    header={'Confirm Parking'}
                    message={confirmationMessage}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                                console.log('Confirm Cancel: blah');
                            }
                        },
                        {
                            text: 'Confirm',
                            handler: () => {
                                doAddParkingHash(selectDuration, hashString);
                            }
                        }
                    ]}
                />
                <IonActionSheet
                    isOpen={showDurationSheet}
                    onDidDismiss={() => setshowDurationSheet(false)}
                    cssClass='my-custom-class'
                    buttons={
                        durationList.map((dur, index) => (
                            {
                                text: dur['name'],
                                handler: () => {
                                    selectedDuration(dur['duration']).then((e: any) => {
                                        doExtendParking(e.durationIs);
                                        window.localStorage.setItem('dura', e.durationIs);
                                    });
                                }
                            }
                        ))
                    }
                >
                </IonActionSheet>
                <IonLabel className="ion-text-wrap" >
                    <p>
                        <FontAwesomeIcon icon="clock"></FontAwesomeIcon> {startTime.format('h.mm A')} - {endTime.format('h.mm A')}, {endTime.format('D MMM YYYY')}
                    </p>
                    <p>
                        <FontAwesomeIcon icon="road"></FontAwesomeIcon> {activeParking.zone_code} <FontAwesomeIcon icon="car"></FontAwesomeIcon> {activeParking.plat_no}
                    </p>
                </IonLabel>
                <IonButton size="small" expand="block" onClick={(e) => { getDurationLists(); }}>Extend Parking</IonButton>
            </IonCardContent>
        </IonCard>
    );
};

export default ActiveParkingListItem;
