import React, { useState } from 'react';
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonLabel,
    IonLoading
} from '@ionic/react';
import { ActivePass } from '../data/activepasses';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCar, faClock, faParking } from '@fortawesome/free-solid-svg-icons';
import configData from '../data/env.json';
import Axios from 'axios';
import { alertMessage } from '../data/alert';

interface ActivePassListItemProps {
    activePass: ActivePass;
}

const config = configData.env[configData.isProd];
const api = Axios.create({
    baseURL: config.apiUrl
});

const returnUrl = window.location.origin + '/home';
const options = {
    headers: { 'Apikey': 'KelantanPay', 'ReturnUrl': returnUrl }
};

const ActivePassListItem: React.FC<ActivePassListItemProps> = ({ activePass }) => {

    library.add(faClock, faParking, faCar);
    var xs_token: any = window.localStorage.getItem('xs_token');
    const [showLoading, setShowLoading] = useState(false);

    function doRenewPass() {
        setShowLoading(true);
        api.post('api/v4/monthlypass/add', { xs_token: xs_token, id: activePass.parking_pass_choices_id, plate_id: activePass.plate_id }, options)
            .then((res) => {
                if (res.status === 200) {
                    setShowLoading(false);
                    window.location.href = res.data.billplz_url;
                }
            }).catch((e) => {
                setShowLoading(false);
                alertMessage(e.response.data.message);
            })
    }

    return (
        <IonCard>
            <IonCardHeader>
                <img src={activePass.parking_pass.logo_image} alt="pbt_logo"></img>
            </IonCardHeader>

            <IonCardContent>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <IonLabel className="ion-text-wrap">
                    <h2>
                        <FontAwesomeIcon icon="clock"></FontAwesomeIcon> {moment(activePass.start_time).format('D MMM YYYY')} - {moment(activePass.end_time).format('D MMM YYYY')}
                    </h2>
                    <h2>
                        <FontAwesomeIcon icon="parking"></FontAwesomeIcon> {activePass.parking_pass.name}
                    </h2>
                    <h2>
                        <FontAwesomeIcon icon="car"></FontAwesomeIcon> {activePass.plate}
                    </h2>
                </IonLabel>
                <IonButton size="small" expand="block" onClick={(e) => { doRenewPass(); }}>Renew</IonButton>
            </IonCardContent>
        </IonCard>
    );
};

export default ActivePassListItem;
