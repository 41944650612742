import { IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonIcon, IonList, IonListHeader, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
import './Parking.css';
import { RouteComponentProps } from 'react-router';
import { chevronBackOutline, logOutOutline } from 'ionicons/icons';
import PbtListItem from '../components/PbtListItem';
import { fetchPbts, Onstreet } from '../data/pbt';

const Parking: React.FC<RouteComponentProps> = ({ history }) => {

    const PbtOnstreetItem = PbtListItem.onstreet;
    // const PbtOffstreetItem = PbtListItem.offstreet;

    const [onstreet, setOnstreet] = useState<Onstreet[]>([]);
    // const [offstreet, setOffstreet] = useState<Offstreet[]>([]);
    const [showLoading, setShowLoading] = useState(false);
    var xs_token: any = window.localStorage.getItem('xs_token');

    useIonViewWillEnter(() => {
        setShowLoading(true);
    });

    useIonViewDidEnter(() => {
        fetchPbts(xs_token).then((res: any) => {
            setOnstreet(res);
            // setOffstreet(res[1]);
            setShowLoading(false);
        });
    });

    const refresh = (e: CustomEvent) => {
        setTimeout(() => {
            e.detail.complete();
        }, 3000);
    };

    return (
        <IonPage id="parking-page">

            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton routerLink="/home">
                                    <IonIcon slot="start" icon={chevronBackOutline} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>Add Parking</IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>


            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={refresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <IonList>
                    <IonListHeader>
                        Onstreet
                    </IonListHeader>
                    {onstreet.map(m => <PbtOnstreetItem key={m.id} onstreet={m} />)}
                    {/* <IonListHeader>
                        Offstreet
                    </IonListHeader>
                    {offstreet.map(m => <PbtOffstreetItem key={m.id} offstreet={m} />)} */}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default Parking;