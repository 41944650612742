import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonIcon, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
// import Home from './pages/Home';
// import ViewMessage from './pages/ViewMessage';
import Start from './pages/Start';
import Login from './pages/Login';
import Register from './pages/Register';
import Verification from './pages/Verification';
import Home from './pages/Home';
import Parking from './pages/Parking';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
// import Homeori from './pages/Homeori';
import ParkingZone from './pages/ParkingZone';
import Topup from './pages/Topup';
import Passes from './pages/Passes';
import Vehicle from './pages/Vehicle';
import Compound from './pages/Compound';
import History from './pages/History';
import AddParking from './pages/AddParking';
import AddVehicle from './pages/AddVehicle';
import { calendarOutline, ellipsisHorizontalOutline, homeOutline, walletOutline } from 'ionicons/icons';
import Details from './pages/Details';
import EditVehicle from './pages/EditVehicle';
import ViewReceipt from './pages/ViewReceipt';
// import Profile from './pages/Profile';

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet>
          <Route path="/home" component={Home} exact={true} />
          {/* <Route path="/homeori" component={Homeori} exact={true} /> */}
          {/* <Route path="/message/:id" component={ViewMessage} exact={true} />  */}
          <Route exact path="/" render={() => <Redirect to="/start" />} />
          <Route path="/start" component={Start} exact={true} />
          <Route path="/login" component={Login} exact={true} />
          <Route path="/register" component={Register} exact={true} />
          <Route path="/verify" component={Verification} exact={true} />
          <Route path="/parking" component={Parking} exact={true} />
          <Route path="/parking/:id" component={ParkingZone} exact={true} />
          <Route path="/parking/:id/add" component={AddParking} exact={true} />
          <Route path="/topup" component={Topup} exact={false} />
          <Route path="/passes" component={Passes} exact={false} />
          <Route path="/vehicles" component={Vehicle} exact={true} />
          <Route path="/vehicles/:id" component={EditVehicle} exact={true} />
          <Route path="/compounds" component={Compound} exact={false} />
          <Route path="/history" component={History} exact={true} />
          <Route path="/vehicles/add" component={AddVehicle} exact={true} />
          <Route path="/details" component={Details} exact={true} />
          <Route path="/receipt/:type/:id" component={ViewReceipt} exact={true} />
          {/* <Route path="/profile" component={Profile} exact={true} /> */}
        </IonRouterOutlet>

        <IonTabBar slot="bottom">
          <IonTabButton tab="home" href="/home">
            <IonIcon icon={homeOutline} />
            <IonLabel>Home</IonLabel>
          </IonTabButton>

          <IonTabButton tab="topup" href="/topup">
            <IonIcon icon={walletOutline} />
            <IonLabel>Reload Token</IonLabel>
          </IonTabButton>

          <IonTabButton tab="passes" href="/passes">
            <IonIcon icon={calendarOutline} />
            <IonLabel>Monthly Pass</IonLabel>
          </IonTabButton>

          <IonTabButton tab="more" href="/details">
            <IonIcon icon={ellipsisHorizontalOutline} />
            <IonLabel>More</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

const Guest: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path="/home" component={Home} exact={true} />
        {/* <Route path="/homeori" component={Homeori} exact={true} /> */}
        {/* <Route path="/message/:id" component={ViewMessage} exact={true} />  */}
        <Route exact path="/" render={() => <Redirect to="/start" />} />
        <Route path="/start" component={Start} exact={true} />
        <Route path="/login" component={Login} exact={true} />
        <Route path="/register" component={Register} exact={true} />
        <Route path="/verify" component={Verification} exact={true} />
        <Route path="/parking" component={Parking} exact={true} />
        <Route path="/parking/:id" component={ParkingZone} exact={true} />
        <Route path="/parking/:id/add" component={AddParking} exact={true} />
        <Route path="/topup" component={Topup} exact={false} />
        <Route path="/passes" component={Passes} exact={false} />
        <Route path="/vehicles" component={Vehicle} exact={true} />
        <Route path="/vehicles/:id" component={EditVehicle} exact={true} />
        <Route path="/compounds" component={Compound} exact={false} />
        <Route path="/history" component={History} exact={true} />
        <Route path="/vehicles/add" component={AddVehicle} exact={true} />
        <Route path="/details" component={Details} exact={true} />
        {/* <Route path="/profile" component={Profile} exact={true} /> */}
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default { App, Guest };
