import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import './Login.css';
import configData from '../data/env.json';
import Axios from 'axios';
import { alertMessage } from '../data/alert';
import ReactDOM from 'react-dom';
import MainApp from '../App';
import { chevronBackOutline } from 'ionicons/icons';

const Login: React.FC<RouteComponentProps> = ({ history }) => {

    const config = configData.env[configData.isProd]
    const api = Axios.create({
        baseURL: config.apiUrl
    })

    const [password, setPassword] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    var phoneNumber: any = window.localStorage.getItem('phone_no');

    function doLogin() {
        setShowLoading(true);
        api.post('/api/v4/account/login', { phone_number: phoneNumber, password: password })
            .then((res) => {
                setShowLoading(false);
                if (res.status === 200) {
                    window.localStorage.setItem('xs_token', res.data.xs_token);
                    if (res.data.user.verified_at != null) {
                        history.replace('/home', { data: res.data });
                    } else {
                        history.replace('/verify');
                    }
                }
            }).catch((err) => {
                alertMessage("The username or password provided is incorrect. Please try again.");
                setShowLoading(false);
            });
    }

    useEffect(() => {
        ReactDOM.render(<MainApp.Guest />, document.getElementById('root'));
    }, [window.localStorage.getItem('xs_token')]);

    return (
        <IonPage id="login-page">

            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton routerLink="/">
                                    <IonIcon slot="start" icon={chevronBackOutline} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle><IonImg src={"/assets/icon/jp_170pt.png"}></IonImg></IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <IonCard>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonItem class="ion-padding">
                                    <IonLabel position="floating">Phone Number</IonLabel>
                                    <IonInput value={phoneNumber}></IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem class="ion-padding">
                                    <IonLabel position="floating">Password</IonLabel>
                                    <IonInput type="password" onIonChange={(e: any) => { setPassword(e.target.value) }}></IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCard>

                <div className="divBottom">
                    <IonButton expand="block" shape="round" onClick={(e) => { doLogin(); }}>Login</IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Login;