import { IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonImg, IonLabel, IonList, IonListHeader, IonLoading, IonPage, IonRow, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import './Home.css';
import { RouteComponentProps } from 'react-router';
import { ActiveParking, fetchActiveParking } from '../data/active';
import ActiveParkingListItem from '../components/ActiveParkingListItem';
import { addOutline } from 'ionicons/icons';
import { ActivePass, fetchActivePasses } from '../data/activepasses';
import ActivePassListItem from '../components/ActivePassListItem';
import configData from '../data/env.json';
import Axios from 'axios';
import ReactDOM from 'react-dom';
import MainApp from '../App';


const Home: React.FC<RouteComponentProps> = ({ history }) => {

    const [activeParking, setActiveParking] = useState<ActiveParking[]>([]);
    const [activePass, setActivePass] = useState<ActivePass[]>([]);
    const [showLoading, setShowLoading] = useState(false);
    const [tokenBalance, setTokenBalance] = useState<number>(0)
    var xs_token: any = window.localStorage.getItem('xs_token');

    const config = configData.env[configData.isProd];
    const api = Axios.create({
        baseURL: config.apiUrl
    });

    useEffect(() => {
        ReactDOM.render(<MainApp.App />, document.getElementById('root'));
    }, [window.localStorage.getItem('xs_token')]);

    useIonViewWillEnter(() => {
        setShowLoading(true);
        api.post('v3/api/account/token', { xs_token: xs_token })
            .then((res) => {
                if (res.status === 200) {
                    setTokenBalance(res.data.response.token);
                }
            })
    });

    useIonViewDidEnter(() => {
        fetchActiveParking(xs_token).then((res: any) => {
            setActiveParking(res);
            setShowLoading(false);
        });
        fetchActivePasses(xs_token).then((res: any) => {
            setActivePass(res);
            setShowLoading(false);
        });
    });

    function checkActivePassLists() {
        if (activePass.length != 0) {
            return (
                activePass.map(m => <ActivePassListItem key={m.id} activePass={m} />)
            );
        } else {
            return (
                <div className="image-center">
                    <div className="center">
                        <IonImg src={"/assets/icon/drawable-xxhdpi/img_no_rfid_illustration.png"}></IonImg>
                        <IonLabel className="center">Ops! No active pass at the moment.</IonLabel>
                    </div>
                </div>
            );
        }
    }

    function checkActiveParkingLists() {
        if (activeParking.length != 0) {
            return (
                activeParking.map(m => <ActiveParkingListItem key={m.id} activeParking={m} />)
            );
        } else {
            return (
                <div className="image-center">
                    <div className="center">
                        <IonImg src={"/assets/icon/drawable-xxhdpi/img_no_active_parking.png"}></IonImg>
                        <IonLabel className="center">Ops! No active parking at the moment.</IonLabel>
                    </div>
                </div>
            );
        }
    }

    return (
        <IonPage id="home-page">

            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonTitle>
                            <IonImg className="jomparkingImage" src={"/assets/icon/jp_170pt.png"}></IonImg>
                        </IonTitle>
                    </IonRow>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonTitle>{tokenBalance} Tokens</IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent id="mainIonContent">
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <div id="scrollableList" className="scrollableList">
                    <IonList>
                        <IonListHeader className="headerFontWeight">Monthly Pass</IonListHeader>
                        {checkActivePassLists()}
                    </IonList>
                    <IonList>
                        <IonListHeader className="headerFontWeight">Active Parking</IonListHeader>
                        {checkActiveParkingLists()}
                    </IonList>
                </div>

                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton routerLink="/parking">
                        <IonIcon icon={addOutline} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    );
};

export default Home;