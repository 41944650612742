import { IonAlert, IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import configData from '../data/env.json';
import Axios from 'axios';
import { alertMessage } from '../data/alert';
import './AddVehicle.css';
import { chevronBackOutline } from 'ionicons/icons';

interface EditVehicleProps extends RouteComponentProps<{ id: string }> { }

const EditVehicle: React.FC<EditVehicleProps> = ({ match, history }) => {

    const config = configData.env[configData.isProd]
    const api = Axios.create({
        baseURL: config.apiUrl
    })

    const [plateNumber, setPlateNumber] = useState('');
    const [description, setDescription] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    const [showAlert1, setShowAlert1] = useState(false);
    const [showAlert3, setShowAlert3] = useState(false);
    var xs_token: any = window.localStorage.getItem('xs_token');
    var data: any = history.location.state;

    function updateVehicle() {
        setShowLoading(true);
        api.post('v3/api/vehicle/update', { xs_token: xs_token, plate_id: parseInt(match.params.id), plate_number: plateNumber, description: description })
            .then((res) => {
                setShowLoading(false);
                if (res.status === 200) {
                    setSuccessMessage(res.data.message);
                    setShowAlert1(true);
                } else {
                    alertMessage(res.data.message);
                }
            }).catch((err) => {
                alertMessage(err.response.data.message);
                setShowLoading(false);
            })
    }

    useIonViewWillEnter(() => {
        setShowLoading(true);
    });

    useIonViewDidEnter(() => {
        setShowLoading(false);
        setPlateNumber(data.plateNum);
        setDescription(data.desc);
    }, [match]);

    return (
        <IonPage id="add-vehicle-page">
            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton routerLink="/vehicles">
                                    <IonIcon slot="start" icon={chevronBackOutline} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>Edit Vehicle</IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <IonAlert
                    isOpen={showAlert3}
                    onDidDismiss={() => setShowAlert3(false)}
                    cssClass='my-custom-class'
                    header={'Confirm!'}
                    message={'Are you sure to update description for ' + plateNumber + '?'}
                    buttons={[
                        {
                            text: 'Cancel',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: blah => {
                            }
                        },
                        {
                            text: 'Yes',
                            handler: () => {
                                updateVehicle();
                            }
                        }
                    ]}
                />
                <IonAlert
                    isOpen={showAlert1}
                    onDidDismiss={() => setShowAlert1(false)}
                    cssClass='my-custom-class'
                    header={'Success!'}
                    message={successMessage}
                    buttons={[{
                        text: 'Okay',
                        handler: () => {
                            history.replace('/vehicles', { data: xs_token });
                        }
                    }]}
                />
                <IonCard>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                <IonItem class="ion-padding">
                                    <IonLabel position="stacked">Plate Number</IonLabel>
                                    <IonInput type="text">{plateNumber}</IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol>
                                <IonItem class="ion-padding">
                                    <IonLabel position="stacked">Description</IonLabel>
                                    <IonInput type="text" value={description} onIonChange={(e: any) => { setDescription(e.target.value) }}></IonInput>
                                </IonItem>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCard>

                <div className="divBottom">
                    <IonButton expand="block" shape="round" onClick={(e) => { setShowAlert3(true); }}>Confirm</IonButton>
                </div>
            </IonContent>

        </IonPage >
    );
};

export default EditVehicle;