import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonPage, IonRow, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { carOutline, chevronBackOutline, ticketOutline, timeOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import './Details.css';
import configData from '../data/env.json';
import Axios from 'axios';
import { alertMessage } from '../data/alert';

const Details: React.FC<RouteComponentProps> = ({ history }) => {

    const config = configData.env[configData.isProd]
    const api = Axios.create({
        baseURL: config.apiUrl
    })

    const [name, setName] = useState('');
    const [avatar, setAvatar] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [showLoading, setShowLoading] = useState(false);
    var xs_token: any = window.localStorage.getItem('xs_token');
    var phoneNumber: any = window.localStorage.getItem('phone_no');

    function getUserInfo() {
        api.post('v3/api/account/info', { xs_token: xs_token })
            .then((res) => {
                setShowLoading(false);
                if (res.status === 200) {
                    setName(res.data.response.user.full_name);
                    if (res.data.response.user.image_path) {
                        setAvatar(config.apiUrl + "/" + res.data.response.user.image_path);
                    }
                    else {
                        setAvatar("https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y");
                    }
                    setUsername(res.data.response.user.username);
                    setEmail(res.data.response.user.email);
                }
            }).catch((e) => {
                alertMessage(e.response.data.message);
                setShowLoading(false);
            });
    }

    useIonViewWillEnter(() => {
        setShowLoading(true);
        getUserInfo();
    });

    return (
        <IonPage id="details-page">

            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton routerLink="/home">
                                    <IonIcon slot="start" icon={chevronBackOutline} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>More</IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent fullscreen>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <IonCard>
                    <IonCardContent>
                        <IonList>
                            <IonItem>
                                <IonAvatar slot="end">
                                    <img className="avatarImage" src={avatar} alt="profile_image" />
                                </IonAvatar>
                                <IonLabel>
                                    <h5>
                                        {name}
                                    </h5>
                                    <p>
                                        {phoneNumber}
                                    </p>
                                </IonLabel>
                            </IonItem>
                            <IonItem>
                                <IonLabel>
                                    <p>
                                        Username
                                    </p>
                                    <h5>
                                        {username}
                                    </h5>
                                </IonLabel>
                            </IonItem>
                        </IonList>
                        <IonItem>
                            <IonLabel>
                                <p>
                                    Email
                                    </p>
                                <h5>
                                    {email}
                                </h5>
                            </IonLabel>
                        </IonItem>
                    </IonCardContent>

                </IonCard>

                <IonCard>
                    <IonCardContent>
                        <IonList>
                            <IonItem detail routerLink="/history">
                                <IonIcon className="iconList" icon={timeOutline}></IonIcon>History
                    </IonItem>
                            <IonItem detail routerLink="/vehicles" lines="none">
                                <IonIcon className="iconList" icon={carOutline}></IonIcon>Vehicle
                    </IonItem>
                            {/* <IonItem detail routerLink="/compounds" lines="none">
                                <IonIcon className="iconList" icon={ticketOutline}></IonIcon>Compound
                    </IonItem> */}
                        </IonList>
                    </IonCardContent>
                </IonCard>
                <IonButton size="default" expand="block" color="danger" routerLink={`/start`}>Log Out</IonButton>
            </IonContent>
        </IonPage>
    );

};
export default Details;