import React, { useState } from 'react';
import { IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonIcon, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { chevronBackOutline, logOutOutline } from 'ionicons/icons';
import { fetchPasses, ParkingPasses } from '../data/passes';
import PassesListItem from '../components/PassesListItem';
import './Passes.css';

const Passes: React.FC<RouteComponentProps> = () => {

    const [passes, setPasses] = useState<ParkingPasses[]>([]);
    const [showLoading, setShowLoading] = useState(false);
    var xs_token: any = window.localStorage.getItem('xs_token');

    useIonViewWillEnter(() => {
        setShowLoading(true);
        fetchPasses(xs_token).then((res: any) => {
            setPasses(res);
            setShowLoading(false);
        });
    });

    const refresh = (e: CustomEvent) => {
        setTimeout(() => {
            e.detail.complete();
        }, 3000);
    };

    return (
        <IonPage id="parking-passes-page">

            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton routerLink="/home">
                                    <IonIcon slot="start" icon={chevronBackOutline} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>Parking Pass</IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={refresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                    <IonGrid>
                    <IonRow>
                    {passes.map(m => <PassesListItem key={m.id} passes={m} />)}
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );

};
export default Passes;