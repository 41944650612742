import React, { useState } from 'react';
import { IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonLabel, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { chevronBackOutline } from 'ionicons/icons';
import { Compounds, fetchCompounds } from '../data/compound';
import CompoundListItem from '../components/CompoundListItem';
import './Compound.css';

const Compound: React.FC<RouteComponentProps> = () => {

    const [compounds, setCompound] = useState<Compounds[]>([]);
    const [showLoading, setShowLoading] = useState(false);
    var xs_token: any = window.localStorage.getItem('xs_token');

    useIonViewWillEnter(() => {
        setShowLoading(true);
        fetchCompounds(xs_token).then((res: any) => {
            setCompound(res);
            setShowLoading(false);
        });
    });

    const refresh = (e: CustomEvent) => {
        setTimeout(() => {
            e.detail.complete();
        }, 3000);
    };

    function checkCompound() {
        if (compounds.length != 0) {
            return (
                <div>
                    {compounds.map(m => <CompoundListItem key={m.id} compounds={m} />)}
                </div>
            );
        } else {
            return (
                <div className="image-center">
                    <div className="center">
                        <IonImg src={"/assets/icon/drawable-xxhdpi/img_compound_empty.png"}></IonImg>
                        <IonLabel className="center">No compound yet</IonLabel>
                    </div>
                </div>
            );
        }
    }


    return (
        <IonPage id="compound-page">

            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton routerLink="/details">
                                    <IonIcon slot="start" icon={chevronBackOutline} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>Compounds</IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent>
                <IonRefresher slot="fixed" onIonRefresh={refresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                {checkCompound()}
            </IonContent>
        </IonPage>
    );

};
export default Compound;