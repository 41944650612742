import { library } from '@fortawesome/fontawesome-svg-core';
import { faCar, faClock, faMoneyBill, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonCard, IonCardContent, IonCardHeader, IonLabel } from '@ionic/react';
import React from 'react';
import { Histories } from '../data/history';
import './HistoryListItem.css';

interface HistoryListItemProps {
    histories: Histories;
}

const HistoryListItem: React.FC<HistoryListItemProps> = ({ histories }) => {

    library.add(faClock, faWallet, faCar, faMoneyBill);
    var paymentMethod;
    var tokenBalance;
    var paidAmount;
    var plateNumber;
    var typeColour;
    var receiptId;

    if (histories.type === 'Topup') {
        if (histories.payment_method) {
            paymentMethod = <p><FontAwesomeIcon icon="money-bill"></FontAwesomeIcon> {histories.payment_method}</p>;
            tokenBalance = <p><FontAwesomeIcon icon="wallet"></FontAwesomeIcon> {histories.token_balance}</p>
            typeColour = <IonLabel className="titleAdjustTopup"><p><small>{histories.type}</small></p></IonLabel>;
            receiptId = histories.token_history_id;
        } else {
            paymentMethod = <p><FontAwesomeIcon icon="money-bill"></FontAwesomeIcon> Promo </p>;
            tokenBalance = <p><FontAwesomeIcon icon="wallet"></FontAwesomeIcon> {histories.token_balance}</p>;
            typeColour = <IonLabel className="titleAdjustTopup"><p><small>{histories.type}</small></p></IonLabel>;
            receiptId = histories.token_history_id;
        }
    } else if (histories.type === 'Monthly Pass' || histories.type === 'Daily Pass') {
        paymentMethod = <p><FontAwesomeIcon icon="money-bill"></FontAwesomeIcon> {histories.payment_method}</p>;
        paidAmount = <p><FontAwesomeIcon icon="wallet"></FontAwesomeIcon> {histories.amount}</p>;
        typeColour = <IonLabel className="titleAdjustMp"><p><small>{histories.type}</small></p></IonLabel>;
        receiptId = histories.parkingpassinvoice_id;
    } else if (histories.type === 'Compound') {
        paymentMethod = <p><FontAwesomeIcon icon="money-bill"></FontAwesomeIcon> {histories.payment_method}</p>;
        paidAmount = <p><FontAwesomeIcon icon="wallet"></FontAwesomeIcon> {histories.amount}</p>;
        typeColour = <IonLabel className="titleAdjustCom"><p><small>{histories.type}</small></p></IonLabel>;
        receiptId = histories.compound_id;
    } else if (histories.type === 'Refund') {
        paymentMethod = <p><FontAwesomeIcon icon="money-bill"></FontAwesomeIcon> Promo </p>;
        tokenBalance = <p><FontAwesomeIcon icon="wallet"></FontAwesomeIcon> {histories.token_balance}</p>
        typeColour = <IonLabel className="titleAdjustRef"><p><small>{histories.type}</small></p></IonLabel>;
        receiptId = histories.token_history_id;
    } else {
        plateNumber = <p><FontAwesomeIcon icon="wallet"></FontAwesomeIcon> {histories.plate_number}</p>;
        tokenBalance = <p><FontAwesomeIcon icon="wallet"></FontAwesomeIcon> {histories.token_balance}</p>;
        typeColour = <IonLabel className="titleAdjust"><p><small>{histories.type}</small></p></IonLabel>;
        receiptId = histories.transaction_id;
    }

    return (
        <IonCard routerLink={`/receipt/${histories.type_no}/${receiptId}`}>
            {typeColour}
            <IonCardHeader>
                <h5 className="cardMargin">
                    {histories.title}
                </h5>
                <p className="cardMargin">
                    <small>
                        {histories.subtitle}
                    </small>
                </p>
            </IonCardHeader>

            <IonCardContent>
                <IonLabel className="ion-text-wrap">
                    <p>
                        <FontAwesomeIcon icon="clock"></FontAwesomeIcon> {histories.transaction_date}
                    </p>
                    {paymentMethod}
                    {tokenBalance}
                    {paidAmount}
                </IonLabel>
            </IonCardContent>
        </IonCard>
    );
};

export default HistoryListItem;