import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import Axios from 'axios';
import React, { useState } from 'react';
import './Register.css';
import configData from '../data/env.json';
import { RouteComponentProps } from 'react-router';
import { alertMessage } from '../data/alert';
import { toast } from '../data/toast';
import { chevronBackOutline } from 'ionicons/icons';

const Register: React.FC<RouteComponentProps> = ({ history }) => {
    const config = configData.env[configData.isProd];

    const api = Axios.create({
        baseURL: config.apiUrl,
    });

    const [username, setUsername] = useState("");
    const [password, setNewPassword] = useState("");
    const [cPassword, setConfirmPassword] = useState("");
    const [email, setEmail] = useState("");
    const [showLoading, setShowLoading] = useState(false);
    var phoneNumber: any = window.localStorage.getItem('phone_no');

    function checkAvailability() {
        if (password !== cPassword) {
            return toast('Password do not match');
        }

        if (username.trim() === '' || password.trim() === '' || email.trim() === '') {
            return toast('All fields are required');
        }

        setShowLoading(true);
        api.post('/api/v4/account/availability', { username: username, password: password, email: email })
            .then((res) => {
                setShowLoading(false);
                if (res.status === 200) {
                    if (res.data.valid === true) {
                        api.post('/api/v4/account/register', { phone_number: phoneNumber, username: username, password: password, email: email })
                            .then((response) => {
                                window.localStorage.setItem('xs_token', response.data.xs_token);
                                history.push('/verify');
                            })
                    } else {
                        alertMessage(res.data.message);
                    }
                } else {
                    alert('Not found phone number');
                }
            })
            .catch((err) => {
                alertMessage('Not receive');
                setShowLoading(false);
            });
    }

    return (
        <IonPage id="register-page">

            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton href="/">
                                    <IonIcon slot="start" icon={chevronBackOutline} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>
                                <IonImg src={"/assets/icon/jp_170pt.png"}></IonImg>
                            </IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <IonCard>
                    <IonGrid>
                        <IonItem class="ion-padding-horizontal">
                            <IonLabel position="stacked">Phone Number</IonLabel>
                            <IonInput value={phoneNumber} disabled></IonInput>
                        </IonItem>

                        <IonItem class="ion-padding-horizontal">
                            <IonLabel position="floating">New Password</IonLabel>
                            <IonInput type="password" onIonChange={(e: any) => { setNewPassword(e.target.value) }}></IonInput>
                        </IonItem>


                        <IonItem class="ion-padding-horizontal">
                            <IonLabel position="floating">Confirmation Password</IonLabel>
                            <IonInput type="password" onIonChange={(e: any) => { setConfirmPassword(e.target.value) }}></IonInput>
                        </IonItem>


                        <IonItem class="ion-padding-horizontal">
                            <IonLabel position="floating">Username</IonLabel>
                            <IonInput onIonChange={(e: any) => { setUsername(e.target.value) }}></IonInput>
                        </IonItem>


                        <IonItem class="ion-padding-horizontal">
                            <IonLabel position="floating">Email</IonLabel>
                            <IonInput onIonChange={(e: any) => { setEmail(e.target.value) }}></IonInput>
                        </IonItem>

                    </IonGrid>
                </IonCard>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <p id="tnc-text" className="ion-text-center ion-margin-horizontal">
                                We’ll never spam you or share your personal information.
                            </p>
                            <p id="tnc-text" className="ion-text-center ion-margin-horizontal">
                                By verify phone number, you agree to Jomparking Terms And Conditions and Privacy Policy
                            </p>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>

            <IonButton shape="round" onClick={(e) => { checkAvailability(); }}>
                Register
            </IonButton>

        </IonPage >
    );
};

export default Register;