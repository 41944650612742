import React, { useEffect, useState } from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonHeader, IonImg, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow, IonTitle, useIonViewDidEnter } from "@ionic/react";
import './Start.css';
import axios from 'axios';
import { RouteComponentProps } from 'react-router';
import configData from '../data/env.json';
import { alertMessage } from '../data/alert';
import ReactDOM from 'react-dom';
import MainApp from '../App';

const Start: React.FC<RouteComponentProps> = ({ history }) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [showLoading, setShowLoading] = useState(false);

    const config = configData.env[configData.isProd];

    const api = axios.create({
        baseURL: config.apiUrl,
    });

    function checkPhoneNumber() {
        setShowLoading(true);
        api.post('/api/v4/account/filter', { phone_number: phoneNumber })
            .then((res) => {
                window.localStorage.setItem('phone_no', phoneNumber);
                setShowLoading(false);
                if (res.status === 200) {
                    if (res.data.registered) {
                        history.push('/login', { data: phoneNumber });
                    } else {
                        history.push('/register', { data: phoneNumber })
                    }
                } else {
                    alert('Not found phone number');
                }
            })
            .catch((err) => {
                alertMessage(err.response.data.message)
                setShowLoading(false);
            });
    }

    useIonViewDidEnter(() => {
        window.localStorage.setItem('xs_token', '');
    });
    useEffect(() => {
        ReactDOM.render(<MainApp.Guest />, document.getElementById('root'));
    }, [window.localStorage.getItem('xs_token')]);

    return (
        <IonPage id="start-page">
            <IonHeader>
                <IonTitle>
                    <IonImg src={"/assets/icon/jp_170pt.png"} />
                </IonTitle>
            </IonHeader>

            <IonContent>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonItem class="ion-padding-horizontal">
                                <IonLabel position="stacked">Phone Number</IonLabel>
                                <IonInput type="number" onIonChange={(e: any) => { setPhoneNumber('60' + e.detail.value) }}>60</IonInput>
                            </IonItem>
                        </IonCol>
                    </IonRow>
                </IonGrid>

                <div className="divBottom">
                    <IonButton expand="block" shape="round" onClick={(e) => { checkPhoneNumber(); }}>
                        Continue
                    </IonButton>
                </div>
            </IonContent>

        </IonPage>
    );
};

export default Start;