import React, { useState } from 'react';
import {
  IonAlert,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCol,
  IonItem,
  IonLabel,
  IonLoading
} from '@ionic/react';
import { Vehicles } from '../data/vehicle';
import { RouteComponentProps, withRouter } from 'react-router';
import './VehicleListItem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import configData from '../data/env.json';
import Axios from 'axios';
import { alertMessage, alertSuccessful } from '../data/alert';

interface VehicleListItemProps extends RouteComponentProps {
  vehicles: Vehicles;
}

const VehicleListItem: React.FC<VehicleListItemProps> = ({ vehicles, history }) => {

  const config = configData.env[configData.isProd];
  const api = Axios.create({
    baseURL: config.apiUrl
  });
  library.add(faTrash);
  const [showLoading, setShowLoading] = useState(false);
  const [showAlert3, setShowAlert3] = useState(false);
  var xs_token: any = window.localStorage.getItem('xs_token');
  var isMonthlyPass;

  if (vehicles.is_monthly_pass == true) {
    isMonthlyPass = <p>Monthly pass active</p>;
  }

  function getEditVehicle(plateNum: string, desc: string) {
    history.push("/vehicles/" + vehicles.id, { plateNum: plateNum, desc: desc });
  }

  function checkDescription() {
    if (vehicles.description) {
      return (
        vehicles.description
      );
    } else {
      return (
        "No description"
      );
    }
  }

  function checkPlateForMonthlyPass() {
    if (vehicles.is_monthly_pass == true) {
      alertMessage("Ops! You can’t remove your vehicle " + vehicles.plate_number + " because monthly pass is still active.");
    } else {
      doRemoveVehicle(vehicles.id);
    }
  }

  function doRemoveVehicle(id: number) {
    setShowLoading(true);
    api.post('v3/api/vehicle/remove', { xs_token: xs_token, plate_id: id })
      .then((res) => {
        if (res.status === 200) {
          window.location.reload();
        }
      }).catch((e) => {
        alertMessage(e.data.response.message);
      })
  }

  return (
    <IonCard onClick={(e) => { getEditVehicle(vehicles.plate_number, vehicles.description); }}>
      <IonLoading
        cssClass='my-custom-class'
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={'Please wait...'} />
      <IonAlert
        isOpen={showAlert3}
        onDidDismiss={() => setShowAlert3(false)}
        cssClass='my-custom-class'
        header={'Confirmation'}
        message={'Are you sure to remove plate number ' + vehicles.plate_number + '?'}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: blah => {
            }
          },
          {
            text: 'Yes',
            handler: () => {
              doRemoveVehicle(vehicles.id);
            }
          }
        ]}
      />
      <IonCardHeader>
        <FontAwesomeIcon icon="trash" className="editRemoveIcon" onClick={() => { checkPlateForMonthlyPass(); }}></FontAwesomeIcon>
        <h3 className="plateNumberAdjustment">{vehicles.plate_number}</h3>
        <p className="descAdjustment">
          <small>
            {checkDescription()}
          </small>
        </p>
      </IonCardHeader>
      <IonCardContent>
        <IonLabel>{isMonthlyPass}</IonLabel>
      </IonCardContent>
    </IonCard>
  );
};

export default withRouter(VehicleListItem);
