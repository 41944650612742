import React, { useState } from 'react';
import { IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonLabel, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import './History.css';
import { chevronBackOutline, logOutOutline } from 'ionicons/icons';
import { fetchHistory, Histories } from '../data/history';
import HistoryListItem from '../components/HistoryListItem';

const History: React.FC<RouteComponentProps> = () => {

    const [histories, setHistory] = useState<Histories[]>([]);
    const [showLoading, setShowLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
    var xs_token: any = window.localStorage.getItem('xs_token');

    useIonViewWillEnter(() => {
        setShowLoading(true);
        fetchHistory(xs_token, currentPage).then((res: any) => {
            setHistory(res);
            setShowLoading(false);
        });
    });

    const refresh = (e: CustomEvent) => {
        setTimeout(() => {
            e.detail.complete();
        }, 3000);
    };

    function searchNext($event: CustomEvent<void>) {
        var nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        fetchHistory(xs_token, nextPage).then((res: any) => {
            var oldHistory = histories;
            res.forEach((item: Histories) => {
                oldHistory.push(item);
            });
            setHistory(oldHistory);
            setDisableInfiniteScroll(true);
            setDisableInfiniteScroll(false);
            ($event.target as HTMLIonInfiniteScrollElement).complete();
        });
    }

    function checkHistory() {
        if (histories.length != 0) {
            return (
                <div>
                    {histories.map(m => <HistoryListItem key={m.id} histories={m} />)}
                </div>
            );
        } else {
            return (
                <div className="image-center">
                    <div className="center">
                        <IonImg src={"/assets/icon/drawable-xxhdpi/img_history_empty.png"}></IonImg>
                        <IonLabel className="center">No history yet</IonLabel>
                    </div>
                </div>
            );
        }
    }

    return (
        <IonPage id="history-page">

            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton routerLink="/details">
                                    <IonIcon slot="start" icon={chevronBackOutline} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>History</IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={refresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />

                {checkHistory()}

                <IonInfiniteScroll threshold="100px"
                    disabled={disableInfiniteScroll}
                    onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
                    <IonInfiniteScrollContent
                        loadingText="Loading more history...">
                    </IonInfiniteScrollContent>
                </IonInfiniteScroll>
            </IonContent>
        </IonPage>
    );

};
export default History;