import configData from './env.json';
import Axios from 'axios';

export interface ActiveParking {
    id: number;
    zone_id: number;
    start_time: number;
    end_time: number;
    elapsed_time: number;
    plat_no: string;
    zone_code: string;
    extendable: boolean;
    zone_type: string;
    qr_code: string;
    qr_charge: string;
    parking_lot: string;
}

var parking: ActiveParking[] = [];

const config = configData.env[configData.isProd];
const api = Axios.create({
    baseURL: config.apiUrl
})

const options = {
    headers: {'Apikey': 'KELANTANPAY'}
  };

export const fetchActiveParking = (xs_token: string) => {
    return new Promise((resolve, reject) => {
        api.post('v3/api/parking/active', {
            xs_token: xs_token
        }, options)
            .then((res) => {
                if (res.status === 200) {
                    parking = res.data.response.parkings;
                    resolve(parking);
                } else {
                    reject(res);
                }
            }).catch((e) => {
                reject(e);
            })
    })
}