import { IonActionSheet, IonCard, IonCardHeader, IonCol, IonLoading } from '@ionic/react';
import React, { useState } from 'react';
import { ParkingPasses } from '../data/passes';
import configData from '../data/env.json';
import Axios from 'axios';
import './PassesListItem.css';
import { alertMessage } from '../data/alert';

interface PassesListItemProps {
    passes: ParkingPasses;
}

const config = configData.env[configData.isProd];
const api = Axios.create({
    baseURL: config.apiUrl
});
const returnUrl = window.location.origin + '/home';
const options = {
    headers: { 'Apikey': 'KelantanPay', 'ReturnUrl': returnUrl }
};

const PassesListItem: React.FC<PassesListItemProps> = ({ passes }) => {
    var xs_token: any = window.localStorage.getItem('xs_token');

    const [showActionSheet, setShowActionSheet] = useState(false);
    const [showPasses, setShowPasses] = useState(false);
    const [plates, setPlates] = useState([]);
    const [selectedPassId, setSelectedPassId] = useState<number>(0);
    const [showLoading, setShowLoading] = useState(false);

    function getPaymentGateway(plate_id: number, pass_id: number) {
        setShowLoading(true);
        api.post('api/v4/monthlypass/add', { xs_token: xs_token, id: pass_id, plate_id: plate_id }, options)
            .then((res) => {
                if (res.status === 200) {
                    window.location.href = res.data.billplz_url;
                    setShowLoading(false);
                }
            }).catch((e) => {
                setShowLoading(false);
                alertMessage(e.response.data.message);
            })
    }

    function getVehicleList(passId: number) {
        api.post('v3/api/vehicle/list', { xs_token: xs_token })
            .then((res) => {
                if (res.status === 200) {
                    setPlates(res.data.response.plates);
                    setSelectedPassId(passId);
                    setShowActionSheet(true);
                }
            })
    }

    function setPassId(id: number) {
        return new Promise((resolve, reject) => {
            resolve(id);
        });
    }

    function setPlateId(id: number, pass_id: number) {
        return new Promise((resolve, reject) => {
            resolve({ plate_id: id, pass_id: pass_id });
        });
    }

    return (
        <IonCol size="6">
            <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => setShowLoading(false)}
                message={'Please wait...'} />
            <IonActionSheet
                isOpen={showActionSheet}
                onDidDismiss={() => setShowActionSheet(false)}
                cssClass='my-custom-class'
                buttons={
                    plates.map((plate, index) => (
                        {
                            text: plate['plate_number'],
                            handler: () => {
                                setPlateId(plate['id'], selectedPassId).then((e: any) => {
                                    getPaymentGateway(e.plate_id, e.pass_id);
                                });
                            }
                        }
                    ))
                }
            >
            </IonActionSheet>
            <IonActionSheet
                isOpen={showPasses}
                onDidDismiss={() => setShowPasses(false)}
                cssClass='my-custom-class'
                buttons={
                    passes.parking_pass_choices.map((pass, index) => (
                        {
                            text: pass.name,
                            handler: () => {
                                setPassId(pass.id).then((e: any) => {
                                    getVehicleList(e);
                                });
                            }
                        }
                    ))
                }
            >
            </IonActionSheet>
            <IonCard className="cardMargin" onClick={(e) => { setShowPasses(true); }}>
                <IonCardHeader><img src={config.apiUrl + "/" + passes.logo} alt="pbt logo"></img></IonCardHeader>
            </IonCard>
        </IonCol>
    );
};

export default PassesListItem;