import configData from '../data/env.json';
import Axios from 'axios';

export interface Topups {
    id: number;
    paid_amount: number;
    token_amount: number;
    is_available: boolean;
}

var topups: Topups[] = [];

const config = configData.env[configData.isProd];
const api = Axios.create({
    baseURL: config.apiUrl
})
const options = {
    headers: { 'Apikey': 'KELANTANPAY' }
};

export const fetchTopup = (xs_token: string) => {
    return new Promise((resolve, reject) => {
        api.post('v3/api/topup/list', { xs_token: xs_token}, options)
        .then((res) => {
            if(res.status === 200){
                topups = res.data.response.topups;
                resolve(topups);
            } else {
                reject(res);
            }
        }).catch((e) => {
            reject(e);
        })
    })
}