import React, { useState } from 'react';
import {
    IonButton,
    IonButtons,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonList,
    IonLoading,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonRow,
    IonTitle,
    IonToolbar,
    useIonViewDidEnter,
    useIonViewWillEnter
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import { RouteComponentProps } from 'react-router';
import './ParkingZone.css';
import { fetchZones, Zones } from '../data/zone';
import ZoneListItem from '../components/ZoneListItem';

interface ParkingZoneProps extends RouteComponentProps<{ id: string }> { }

const ParkingZone: React.FC<ParkingZoneProps> = ({ match }) => {

    const [zones, setZones] = useState<Zones[]>([]);
    const [showLoading, setShowLoading] = useState(false);
    var xs_token: any = window.localStorage.getItem('xs_token');

    useIonViewWillEnter(() => {
        setShowLoading(true);
    });
    useIonViewDidEnter(() => {
        fetchZones(xs_token, parseInt(match.params.id, 10)).then((res: any) => {
            setZones(res);
            window.localStorage.setItem('pbt_name', res[0].pbt_name);
            window.localStorage.setItem('pbt_logo', res[0].pbt_logo);
            window.localStorage.setItem('zone', JSON.stringify(res));
            setShowLoading(false);
        });
    }, [match]);

    const refresh = (e: CustomEvent) => {
        setTimeout(() => {
            e.detail.complete();
        }, 3000);
    };

    return (
        <IonPage id="list-zone-page">
            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton routerLink="/parking">
                                    <IonIcon slot="start" icon={chevronBackOutline} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>Add Parking</IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>


            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={refresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <IonList>
                    {zones.map(m => <ZoneListItem key={m.id} zones={m} />)}
                </IonList>
            </IonContent>
        </IonPage>
    );
};

export default ParkingZone;
