import configData from './env.json';
import Axios from 'axios';

export interface ActivePass {
    id: number,
    user_id: number,
    parking_pass_id: number,
    start_time: string,
    end_time: string,
    created_at: string,
    updated_at: string,
    plate_id: number,
    price: number,
    tax: number,
    reimbursement_after_tax: number,
    reimbursement_before_tax: number,
    parking_pass_choices_id: number,
    app_name: string,
    lot_number: number,
    status: boolean,
    zone_type: string,
    plate: string,
    parking_pass_zone_id: number,
    parking_pass: {
        name: string,
        is_available: boolean,
        logo_image: string,
        approval_flag: boolean,
        pbt_id: number,
        app_name: null,
        is_special_bay: boolean,
        is_available_web: boolean
    },
    renewal: boolean
}

var passes: ActivePass[] = [];

const config = configData.env[configData.isProd];
const api = Axios.create({
    baseURL: config.apiUrl
})
const options = {
    headers: {'Apikey': 'KELANTANPAY'}
  };

export const fetchActivePasses = (xs_token: string) => {
    return new Promise((resolve, reject) => {
        api.post('v3/api/parking/active', {
            xs_token: xs_token
        }, options)
            .then((res) => {
                if (res.status === 200) {
                    passes = res.data.response.monthly_pass;
                    resolve(passes);
                } else {
                    reject(res);
                }
            }).catch((e) => {
                reject(e);
            })
    })
}