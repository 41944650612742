import configData from '../data/env.json';
import Axios from 'axios';

export interface Compounds {
    id: number;
    status: boolean;
    collection_serial: string;
    payment_amount: number;
    payment_medium: string;
    payment_datetime: string;
    act: string;
    datetime: string;
    parkinglot_number: string;
    pbt_name: string;
    pbt_logo_url: string;
    area: string;
    road: string;
    plate_number: string;
    vehicle_type: string;
    vehicle_model: string;
    vehicle_color: string
    roadtax_number: string;
    clamping_instruction: string;
    compound_amount: number;
    clamp_amount: number;
    offence: string;
    total_amount: number;
}

var compounds: Compounds[] = [];

const config = configData.env[configData.isProd];
const api = Axios.create({
    baseURL: config.apiUrl
});


export const fetchCompounds = (xs_token: string) => {
    return new Promise((resolve, reject) => {
        api.get('api/v4/compound/list', {
            params: {
                xs_token: xs_token
            },
            headers: { 'Apikey': 'KELANTANPAY' }
        })
            .then((res) => {
                if (res.status === 200) {
                    compounds = res.data.compound;
                    resolve(compounds);
                } else {
                    reject(res);
                }
            }).catch((e) => {
                reject(e);
            })
    })
}