import configData from '../data/env.json';
import Axios from 'axios';

export interface ParkingPasses {
    id: number;
    name: string;
    days: number;
    price: string;
    approval_flag: boolean;
    pbt_id: number;
    logo: string;
    app_name: string;
    is_special_bay: boolean;
    is_available_web: boolean;
    zone_type: string;
    is_available: boolean;
    parking_pass_choices: [{
        id: number;
        parking_pass_id: number;
        name: string;
        is_visible_public: boolean;
        is_daily_pass: boolean;
        price: string;
    }]
}

var passes: ParkingPasses[] = [];

const config = configData.env[configData.isProd];
const api = Axios.create({
    baseURL: config.apiUrl
});
const options = {
    headers: {'Apikey': 'KELANTANPAY'}
  };

export const fetchPasses = (xs_token: string) => {
    return new Promise((resolve, reject) => {
        api.post('v3/api/topup/list', { xs_token: xs_token}, options)
        .then((res) => {
            if(res.status === 200){
                passes = res.data.response.passes;
                resolve(passes);
            } else {
                reject(res);
            }
        }).catch((e) => {
            reject(e);
        })
    })
}