import { IonBackButton, IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonImg, IonPage, IonRow, IonTitle, useIonViewDidEnter } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './Verification.css';
import ReactInputVerificationCode from 'react-input-verification-code';
import configData from '../data/env.json';
import Axios from 'axios';
import { RouteComponentProps } from 'react-router';
import { alertMessage } from '../data/alert';
import ReactDOM from 'react-dom';
import MainApp from '../App';
import { fetchVehicles } from '../data/vehicle';
import { toast } from '../data/toast';

const Verification: React.FC<RouteComponentProps> = ({ history }) => {

    const config = configData.env[configData.isProd];

    const api = Axios.create({
        baseURL: config.apiUrl,
    });

    const [value, setValue] = React.useState('');
    const clearValue = () => setValue('');
    var [verificationCode, setVerificationCode] = useState(null);
    var xsToken: any = window.localStorage.getItem('xs_token');
    var phoneNumber: any = window.localStorage.getItem('phone_no');

    function getVerificationCode() {
        api.post('api/v4/account/verify_phone_number', { xs_token: xsToken })
            .then((res) => {
                if (res.status === 200) {
                    setVerificationCode(res.data.code);
                }
            }).catch((e) => {
                alertMessage(e.response.data.message);
            })
    }

    useIonViewDidEnter(() => {
        getVerificationCode();
    });

    useEffect(() => {
        ReactDOM.render(<MainApp.Guest />, document.getElementById('root'));
    }, [window.localStorage.getItem('xs_token')]);


    function checkInsertedCode() {
        var code = parseInt(value)
        if (verificationCode === code) {
            api.post('api/v4/account/verify_user', { xs_token: xsToken, phone_number: phoneNumber })
                .then((res) => {
                    if (res.status === 200) {
                        fetchVehicles(xsToken).then((res: any) => {
                            if (res) {
                                history.push("/home");
                            } else {
                                history.push('vehicles');
                            }
                        })
                    }
                }).catch((e) => {
                    alertMessage(e.response.data.message);
                })
        } else {
            toast("You have entered invalid verification code");
        }
    }

    function doResendCode() {
        getVerificationCode();
    }

    return (
        <IonPage id="verification-page">

            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonButtons slot="start">
                            <IonBackButton defaultHref="/" />
                        </IonButtons>
                        <IonTitle>
                            <IonImg src={"/assets/icon/jp_170pt.png"}></IonImg>
                        </IonTitle>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent>
                <IonGrid>
                    <IonRow>
                        <h4 className="fontStyling">
                            We have sent a code to
                        </h4>
                    </IonRow>
                    <IonRow>
                        <h3 className="fontStyling">
                            {phoneNumber}
                        </h3>
                    </IonRow>
                    <IonRow>
                        <div className="verification-input">
                            <ReactInputVerificationCode onChange={setValue} value={value} />

                        </div>
                    </IonRow>
                    <IonRow>
                        <button onClick={clearValue} className="clearButton">Clear</button>
                    </IonRow>
                    <IonRow>
                        <IonButton fill="clear" expand="full" className="resendCodeButton" onClick={(e) => doResendCode()}>
                            Resend Code
                        </IonButton>
                    </IonRow>
                </IonGrid>

                <div className="divBottom">
                    <IonButton expand="block" shape="round" onClick={(e) => { checkInsertedCode(); }}>
                        Continue
                    </IonButton>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Verification;