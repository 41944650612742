import { IonButton, IonButtons, IonCard, IonCardContent, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonNote, IonPage, IonRow, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';
import './ViewReceipt.css';
import configData from '../data/env.json';
import Axios from 'axios';
import moment from 'moment';

interface HistoryListItemProps extends RouteComponentProps<{ type: string, id: string; }> { }

const ViewReceipt: React.FC<HistoryListItemProps> = ({ match }) => {

    const config = configData.env[configData.isProd];
    const api = Axios.create({
        baseURL: config.apiUrl
    });
    const [receipt, setReceipt] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    var xs_token: any = window.localStorage.getItem('xs_token');
    var params: any;

    useIonViewWillEnter(() => {
        setShowLoading(true);
    })

    useIonViewDidEnter(() => {
        if (parseInt(match.params.type) == 1 || parseInt(match.params.type) == 4 || parseInt(match.params.type) == 6) {
            params = { xs_token: xs_token, token_history_id: match.params.id };
        }

        if (parseInt(match.params.type) == 2 || parseInt(match.params.type) == 3) {
            params = { xs_token: xs_token, transaction_id: match.params.id };
        }

        if (parseInt(match.params.type) == 7) {
            params = { xs_token: xs_token, parking_pass_invoice_id: match.params.id };
        }

        if (parseInt(match.params.type) == 11) {
            params = { xs_token: xs_token, compound_id: match.params.id };
        }

        api.get('api/v4/receipt', {
            params: params
        }).then((res) => {
            if (res.status === 200) {
                setReceipt(res.data);
            }
        })

        setShowLoading(false);
    }, [match]);

    var getReceipt = Object.keys(receipt).map(function (key: any) {
        var keyName = key.charAt(0).toUpperCase() + key.slice(1);
        keyName = keyName.replace('_', ' ')
        var keyValue: any = receipt[key];
        if (key === 'start_time' || key === 'end_time') {
            keyValue = moment(receipt[key]['date'], 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY, h:mm A');
        }

        if (key.match("amount") && !keyValue.match("RM")) {
            keyValue = "RM " + keyValue + ".00";
        }

        if (key === 'plate_number' && receipt[key] === null) {
            keyValue = "-";
        }

        return (
            <div key={key}>
                <IonItem>
                    <IonLabel>{keyName}</IonLabel>
                    <IonNote slot="end" className="noteAdjust">{keyValue}</IonNote>
                </IonItem>
            </div>
        );
    });

    return (
        <IonPage id="view-receipt-page">
            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton routerLink="/history">
                                    <IonIcon slot="start" icon={chevronBackOutline} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>Receipt</IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent fullscreen>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <IonCard>
                    <IonCardContent>
                        <IonList>
                            {getReceipt}
                        </IonList>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    );
};

export default ViewReceipt;
