import React from 'react';
import {
  IonItem,
  IonLabel
} from '@ionic/react';
import { Onstreet, Offstreet } from '../data/pbt';
import './PbtListItem.css';

interface PbtListItemOnstreetProps {
  onstreet: Onstreet;
}

interface PbtListItemOffstreetProps {
  offstreet: Offstreet;
}

const PbtOnstreetItem: React.FC<PbtListItemOnstreetProps> = ({ onstreet }) => {
  return (
    <IonItem routerLink={`/parking/${onstreet.id}`} detail={false}>
      <img className="logoImage" src={onstreet.logo} alt="pbt logo"></img>
      <IonLabel className="ion-text-wrap">
        <h2>
          {onstreet.pbt_name}
        </h2>
        <h3>{onstreet.state.state_name}</h3>
      </IonLabel>
    </IonItem>
  );
};

const PbtOffstreetItem: React.FC<PbtListItemOffstreetProps> = ({ offstreet }) => {
  return (
    <IonItem routerLink={`/parking/${offstreet.id}`} detail={false}>
      <img className="logoImage" src={offstreet.logo} alt="pbt logo"></img>
      <IonLabel className="ion-text-wrap">
        <h2>
          {offstreet.pbt_name}
        </h2>
        <h3>{offstreet.state.state_name}</h3>
      </IonLabel>
    </IonItem>
  );
};

export default {onstreet: PbtOnstreetItem, offstreet: PbtOffstreetItem};
