import configData from '../data/env.json';
import Axios from 'axios';

export interface Vehicles {
    id: number;
    plate_number: string;
    description: string;
    car_model: string;
    car_year: string;
    is_rfid_enabled: boolean;
    is_monthly_pass: boolean;
    rfid_tag: string;
}

var vehicles: Vehicles[] = [];

const config = configData.env[configData.isProd];
const api = Axios.create({
    baseURL: config.apiUrl
})

export const fetchVehicles = (xs_token: string) => {
    return new Promise((resolve, reject) => {
        api.post('v3/api/vehicle/list', { xs_token: xs_token })
            .then((res) => {
                if (res.status === 200) {
                    vehicles = res.data.response.plates;
                    resolve(vehicles);
                } else {
                    reject(res);
                }
            }).catch((e) => {
                reject(e);
            })
    })
}