import configData from '../data/env.json';
import Axios from 'axios';

export interface Histories {
    id: number;
    user_id: number;
    type: string;
    title: string;
    subtitle: string;
    color: string;
    transaction_date: string;
    token_balance: string;
    amount: string;
    payment_method: string;
    plate_number: string;
    token_history_id: number;
    parkingpassinvoice_id: number;
    transaction_id: number;
    type_no: number;
    app_language: string;
    transaction_at: string;
    pg_bill_id: number;
    app_name: string;
    lot_no: string;
    compound_id: number;
}

var histories: Histories[] = [];

const config = configData.env[configData.isProd];
const api = Axios.create({
    baseURL: config.apiUrl
});
const options = {
    headers: { 'Apikey': 'KELANTANPAY' }
};

export const fetchHistory = (xs_token: string, page: number) => {
    return new Promise((resolve, reject) => {
        api.post('api/v4/account/history', { xs_token: xs_token, page: page }, options)
            .then((res) => {
                if (res.status === 200) {
                    histories = res.data.token_history; 
                    resolve(histories);
                } else {
                    reject(res);
                }
            }).catch((e) => {
                reject(e);
            })
    })
}