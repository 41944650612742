import configData from '../data/env.json';
import Axios from 'axios';

export interface Onstreet {
    id: number;
    pbt_name: string;
    logo: string;
    state_id: number;
    priority: number;
    state: {
        id: number;
        state_name: string;
    };
}
export interface Offstreet {
    id: number;
    pbt_name: string;
    logo: string;
    state_id: number;
    priority: number;
    state: {
        id: number;
        state_name: string;
    };
}

var onstreet: Onstreet[] = [];
// var offstreet: Offstreet[] = [];

const config = configData.env[configData.isProd];
const api = Axios.create({
    baseURL: config.apiUrl
});
const options = {
    headers: { 'Apikey': 'KELANTANPAY' }
};

export const fetchPbts = (xs_token: string) => {
    return new Promise((resolve, reject) => {
        api.post('/api/v4/parking/pbt', { xs_token: xs_token }, options)
            .then((res) => {
                if (res.status === 200) {

                    resolve(res.data.onstreet);
                }

                else {
                    reject(res);
                }
            }).catch((e) => {
                reject(e);
            });
    });
};