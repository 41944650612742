export function alertMessage(message: string) {
  const alertMessage = document.createElement('ion-alert');
  alertMessage.cssClass = 'my-custom-class';
  alertMessage.header = 'Oops!';
  alertMessage.message = message;
  alertMessage.buttons = ['OK'];

  document.body.appendChild(alertMessage);
  return alertMessage.present();
}

export function alertSuccessful(message: string) {
  const alertSuccessful = document.createElement('ion-alert');
  alertSuccessful.cssClass = 'my-custom-class';
  alertSuccessful.header = 'Success!';
  alertSuccessful.message = message;
  alertSuccessful.buttons = ['OK'];

  document.body.appendChild(alertSuccessful);
  return alertSuccessful.present();
}