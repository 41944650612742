import React, { useState } from 'react';
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonLabel,
    IonLoading
} from '@ionic/react';
import { Compounds } from '../data/compound';
import './CompoundListItem.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClock, faShieldAlt, faWallet } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import configData from '../data/env.json';
import Axios from 'axios';
import { alertMessage } from '../data/alert';

interface CompoundListItemProps {
    compounds: Compounds;
}
const config = configData.env[configData.isProd];
const api = Axios.create({
    baseURL: config.apiUrl
});
const returnUrl = window.location.origin + '/compounds';
const options = {
    headers: { 'Apikey': 'KelantanPay', 'ReturnUrl': returnUrl }
};

const CompoundListItem: React.FC<CompoundListItemProps> = ({ compounds }) => {

    library.add(faClock, faWallet, faShieldAlt);
    const [showLoading, setShowLoading] = useState(false);
    var xs_token: any = window.localStorage.getItem('xs_token');

    function getPaymentGateway(id: number) {
        setShowLoading(true);
        api.post('api/v4/compound/payment', { xs_token: xs_token, compound_id: id, compound_amount: compounds.compound_amount, total_amount: compounds.total_amount, clamp_amount: compounds.clamp_amount }, options)
            .then((res) => {
                if (res.status === 200) {
                    setShowLoading(false);
                    window.location.href = res.data.billplz_url;
                }
            }).catch((e) => {
                setShowLoading(false);
                alertMessage(e.response.data.message);
            })
    }

    function getPaidAmount(status: boolean) {
        if (status == true) {
            return (
                <p>
                    <FontAwesomeIcon icon="wallet"></FontAwesomeIcon> RM {compounds.payment_amount}.00
                </p>
            );
        }
    }

    function checkPaidStatus(status: boolean) {

        if (status == false) {
            return (
                <IonButton expand="block" size="small" onClick={(e) => { getPaymentGateway(compounds.id); }}>Pay Now</IonButton>
            )
        }
    }

    return (
        <IonCard>
            <IonCardHeader>
                <h5 className="cardMargin">
                    {compounds.plate_number}
                </h5>
                <p className="cardMargin">
                    <small>
                        {compounds.pbt_name}
                    </small>
                </p>
            </IonCardHeader>

            <IonCardContent>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <IonLabel className="ion-text-wrap">
                    <p>
                        <FontAwesomeIcon icon="clock"></FontAwesomeIcon> {moment(compounds.datetime, 'YYYY-MM-DD HH:mm:ss').format('D MMM YYYY, h:mm A')}
                    </p>
                    {getPaidAmount(compounds.status)}
                    <p>
                        <FontAwesomeIcon icon="shield-alt"></FontAwesomeIcon> {compounds.offence}
                    </p>
                </IonLabel>
                {checkPaidStatus(compounds.status)}
            </IonCardContent>
        </IonCard>
    );
};

export default CompoundListItem;
