import React, { useState } from 'react';
import { IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonIcon, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import './Topup.css';
import { chevronBackOutline, logOutOutline } from 'ionicons/icons';
import TopupListItem from '../components/TopupListItem';
import { fetchTopup, Topups } from '../data/topup';

const Topup: React.FC<RouteComponentProps> = () => {

    const [topups, setTopup] = useState<Topups[]>([]);
    const [showLoading, setShowLoading] = useState(false);
    var xs_token: any = window.localStorage.getItem('xs_token');

    useIonViewWillEnter(() => {
        setShowLoading(true);
        fetchTopup(xs_token).then((res: any) => {
            setTopup(res);
            setShowLoading(false);
        });
    });

    const refresh = (e: CustomEvent) => {
        setTimeout(() => {
            e.detail.complete();
        }, 3000);
    };

    return (
        <IonPage id="topup-page">

            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton routerLink="/home">
                                    <IonIcon slot="start" icon={chevronBackOutline} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>Reload Token</IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={refresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                <IonGrid>
                    <IonRow>
                        {topups.map(m => <TopupListItem key={m.id} topups={m} />)}
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonPage>
    );

};
export default Topup;