import React, { useState } from 'react';
import { IonButton, IonButtons, IonContent, IonGrid, IonHeader, IonIcon, IonImg, IonLabel, IonLoading, IonPage, IonRefresher, IonRefresherContent, IonRow, IonTitle, IonToolbar, useIonViewDidEnter, useIonViewWillEnter } from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { chevronBackOutline, logOutOutline } from 'ionicons/icons';
import { fetchVehicles, Vehicles } from '../data/vehicle';
import VehicleListItem from '../components/VehicleListItem';
import './Vehicle.css';

const Vehicle: React.FC<RouteComponentProps> = () => {

    const [vehicles, setVehicle] = useState<Vehicles[]>([]);
    const [showLoading, setShowLoading] = useState(false);
    var xs_token: any = window.localStorage.getItem('xs_token');

    useIonViewWillEnter(() => {
        setShowLoading(true);
    })

    useIonViewDidEnter(() => {
        fetchVehicles(xs_token).then((res: any) => {
            setVehicle(res);
            setShowLoading(false);
        });
    });

    function checkVehicles() {
        if (vehicles.length != 0) {
            return (
                <div>
                    {vehicles.map(m => <VehicleListItem key={m.id} vehicles={m} />)}
                </div>
            );
        } else {
            return (
                <div className="image-center">
                    <div className="center">
                        <IonImg src={"/assets/icon/drawable-xxhdpi/img_vehicle_illustration.png"}></IonImg>
                        <IonLabel className="center">Add your vehicle to start using JomParking</IonLabel>
                    </div>
                </div>
            );
        }
    }

    const refresh = (e: CustomEvent) => {
        setTimeout(() => {
            e.detail.complete();
        }, 3000);
    };

    return (
        <IonPage id="vehicle-page">

            <IonHeader>
                <IonGrid>
                    <IonRow>
                        <IonToolbar color="primary">
                            <IonButtons slot="start">
                                <IonButton routerLink="/details">
                                    <IonIcon slot="start" icon={chevronBackOutline} />
                                </IonButton>
                            </IonButtons>
                            <IonTitle>Vehicle</IonTitle>
                        </IonToolbar>
                    </IonRow>
                </IonGrid>
            </IonHeader>

            <IonContent fullscreen>
                <IonRefresher slot="fixed" onIonRefresh={refresh}>
                    <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
                <IonLoading
                    cssClass='my-custom-class'
                    isOpen={showLoading}
                    onDidDismiss={() => setShowLoading(false)}
                    message={'Please wait...'} />
                {checkVehicles()}
            </IonContent>

            <IonButton shape="round" routerLink={'vehicles/add'}>
                Add Vehicle
            </IonButton>
        </IonPage>
    );

};
export default Vehicle;