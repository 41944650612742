import React from 'react';
import {
    IonItem,
    IonLabel
} from '@ionic/react';
import './ZoneListItem.css';
import { Zones } from '../data/zone';

interface ZoneListItemProps {
    zones: Zones;
}

const navigateSelect = (zones: Zones) => {
    window.localStorage.setItem('selectedZone', JSON.stringify(zones));
};

const ZoneListItem: React.FC<ZoneListItemProps> = ({ zones }) => {
    return (
        <IonItem key={zones.id} routerLink={`/parking/${zones.id}/add`} onClick={e => navigateSelect(zones)} detail={false}>
            <IonLabel className="ion-text-wrap">
                <h2>
                    {zones.zone_code}
                </h2>
                <h3>{zones.toc}</h3>
            </IonLabel>
        </IonItem>
    );
};

export default ZoneListItem;
